import * as React from "react";

import { Header } from "components/Header";

export default class extends React.Component {
    public render() {
        return (
            <>
                <Header />
            </>
        );
    }
}
